<template>
    <div class="scrollLazy" style="min-height: 200px">
        <div class="appdownload__appDownload__28OI- animation-show" id="appDownload" animation-show="true">
            <div class="apppng1"><img  style="    margin: 0 auto;"  src="/static/image/app.png"  width="200px"  />
              <img src="\static\image\gaming\right.png" alt="left image" class="left-image1" />
                <span>  自研原生精品 极致投注体验 </span>
                <img src="\static\image\gaming\left.png" alt="right image" class="right-image1" /></div>
            <div class="appdownload__downContainer__2Yz3A">
                <div class="appdownload__leftSide__2K_EM">
                    <div class="appdownload__appDownloadOne__2Bth6 appdownload__active__1p3Q_"><img src="/static/image/appxz.png" width="698px" class="appdownload__iphone1__d-ys5 appdownload__iphone1enter__18nrh" data-type="zh" /></div>
                    <div class="appdownload__appDownloadOne__2Bth6"><img src="/static/image/tiyu.320cff4864a85c7829620bf8577082fc.png" width="698px" class="appdownload__iphone2__1KetS appdownload__iphone1enter__18nrh" data-type="ty" /></div>
                    <div class="appdownload__appDownloadOne__2Bth6"><img src="/static/image/cp.f7fd720b5960205cd8dfeaec4c048379.png" width="698px" class="appdownload__iphone3__1mcq5 appdownload__iphone1enter__18nrh" data-type="cp" /></div>
                    <div class="appdownload__appDownloadOne__2Bth6"><img src="/static/image/qp.07e41c5b711af6021e3cc2378e21b948.png" width="696px" class="appdownload__iphone3__1mcq5 appdownload__iphone1enter__18nrh" data-type="qp" /></div>
                </div>
                <div class="appdownload__rightSide__gWFaK">
                    <div class="appdownload__tabs__3tvRx"></div>
                    <div class="appdownload__rightSideContainer__2lGDq appdownload__active__1p3Q_">
                        <img src="/static/image/app-sub-title.ea5de3f59f0d4e8129b37826dc6272fb.png" width="218px" class="appdownload__enjoy__3FakM" />
                        <p class="appdownload__description__3YMx1">棋牌、彩票、真人娱乐、体育赛事、电子游艺、电子竞技，您所要的尽在{{ $store.state.appInfo.title }}。行业种类最全娱乐竞技APP，{{ $store.state.appInfo.title }}为您呈现别样、缤纷的极致体验。</p>
                        <div class="appdownload__qcodeContainer__3VX0A">
                            <!--           // "ios_download_qrcode": "http://mb2.tg-demo.cc:86/uploads/images/e13177382b3e958789c877f71b034ca9.jpg",
                  // "ios_download_url": "http://mb2.tg-demo.cc:86/",
                  // "h5_url": "http://mb2m.tg-demo.cc" -->
                            <div class="appdownload__topSide__27gqv">
                                <div class="appdownload__codeWrapper__3eWWV">
                                    <img :src="$store.state.appInfo.android_download_qrcode" onerror="this.src = '/static/image/appurl.jpg'" width="152" height="152" />
                                </div>
                                <div>
                                    <p class="appdownload__botSide__3kjmH">
                                        扫码下载aaAPP<br />
                                        支持IOS &amp; Android全设备<br />
                                        <a :href="$store.state.appInfo.android_download_url" target="_blank" style="color: rgb(70, 119, 255); visibility: visible">{{ $store.state.appInfo.android_download_url }}</a>
                                    </p>
                                </div>
                            </div>
                            <div class="appdownload__topSide__27gqv appdownload__topSide1__3qE_b">
                                <div class="appdownload__codeWrapper__3eWWV"><img src="/static/image/wxxz.png" width="100%" class="appdownload__h5__2f17w" style="margin-left: 10px" /></div>
                                <div>
                                    <p class="appdownload__botSide__3kjmH undefined">
                                        无需下载直接访问<br />
                                        手机输入网址即可访问<br />
                                        <a :href="$store.state.appInfo.h5_url" style="color: rgb(70, 119, 255); cursor: pointer; visibility: visible">{{ $store.state.appInfo.h5_url.split(',')[0] }}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'App',
    // 这里可以添加组件的逻辑，比如data, methods, computed等
}
</script>

<style scoped>
.apppng1{
  margin-left: 65px;
  text-align:center;
  font-size: 15px;
  color: #d79514;
  
}
.left-image1, .right-image1 {
  content: "";
    position: relative;
    top: 5px;
    width: 86px;
    height: 20px;
    display: inline-block;
}

/* 这里可以添加组件的样式 */
/* 注意：原HTML中使用的是CSS模块的类名，你可能需要调整样式的写法 */
.scrollLazy {
    min-height: 200px;
}

/* 添加其他必要的样式... */
</style>
